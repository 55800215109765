import styles from './styles.module.scss';

import clsx from 'clsx';

import { ArrowLeft, ArrowRight } from '../../DesignToken/Icons';
import { Cluster } from '../../Layouts/Cluster';

type Props = {
  itemsCount: number;
  visibleItemsCount: number;
  currentItemIndex: number;
  onClickPrev: VoidFunction;
  onClickNext: VoidFunction;
  disabled?: boolean;
};

export const ChartSlider: React.FC<Props> = (props) => {
  return (
    <Cluster gap={8} align="stretch" width="initial">
      <button
        className={clsx(
          styles.arrow,
          (props.disabled ||
            props.currentItemIndex === 0 ||
            props.itemsCount <= props.visibleItemsCount) &&
            styles.inactive,
        )}
        onClick={props.onClickPrev}
      >
        <ArrowLeft size={24} />
      </button>
      <button
        className={clsx(
          styles.arrow,
          (props.disabled ||
            props.currentItemIndex + props.visibleItemsCount >=
              props.itemsCount) &&
            styles.inactive,
        )}
        onClick={props.onClickNext}
      >
        <ArrowRight size={24} />
      </button>
    </Cluster>
  );
};
