import { useGetOwnDepartments } from '@/src/hooks/fetcher/useGetOwnDepartments';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';
import { useResponsive } from '@/src/hooks/useResponsive';

import { OwnDepartmentFilterModalPresenter, ModalInputMap } from './presenter';

type Props = Pick<
  React.ComponentProps<typeof OwnDepartmentFilterModalPresenter>,
  'isShown' | 'onClose'
> & {
  selectedOwnDepartmentIds: string[];
  onSubmit: (ownDepartmentIds: string[]) => void;
};

export const OwnDepartmentFilterModal: React.FC<Props> = (props) => {
  const ownDepartments = useGetOwnDepartments();
  const responsive = useResponsive();

  const form = useFormWrapper<ModalInputMap>({
    mode: 'all',
    defaultValues: {
      ownDepartments: props.selectedOwnDepartmentIds,
    },
  });

  const inputMap = form.watch();

  return ownDepartments.data?.departments ? (
    <OwnDepartmentFilterModalPresenter
      isShown={props.isShown}
      onClose={props.onClose}
      ownDepartmentOptions={ownDepartments.data.departments}
      form={{
        onSubmit: form.handleSubmit((data) => {
          if (ownDepartments.data) {
            props.onSubmit(data.ownDepartments);
          }
        }),
        ownDepartments: {
          ...form.register('ownDepartments'),
          errorMessages: [],
          allSelect: {
            label: '全てを選択',
            onClick: () => {
              if (ownDepartments.data) {
                form.setValue(
                  'ownDepartments',
                  ownDepartments.data.departments.map(
                    (departmentOption) => departmentOption.key,
                  ),
                );
              }
            },
          },
          allClear: {
            label: '全てを解除',
            onClick: () => form.setValue('ownDepartments', []),
          },
          isAllSelected:
            inputMap.ownDepartments.length ===
            ownDepartments.data.departments.length,
        },
      }}
      isSp={responsive.isSp}
    />
  ) : null;
};
