import { Payload } from '@/src/types/chart';

export const CHART_PROPERTIES = {
  xaxislLne: false,
  yaxisLine: false,
  xtickLine: false,
  ytickLine: false,
};

type PayloadProps = Omit<Payload, 'type'>[] | undefined;

export const useChart = (): {
  createPayload: (payload: PayloadProps) => Payload[];
} => {
  const addTypeRect = (item: Omit<Payload, 'type'>): Payload => ({
    value: item.value,
    color: item.color,
    type: 'rect',
  });

  const createPayload = (payload: PayloadProps) => {
    if (!payload) {
      return [];
    }
    return payload.map((item) => addTypeRect(item));
  };

  return {
    createPayload,
  };
};
