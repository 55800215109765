export const COLOR = {
  PRIMARY: '#4E5EF2',
};

export const PREVIOUS_COLOR = {
  LAST_TIME: '#B5B9BA',
  TIME_BEFORE_LAST: '#CDD0D1',
};

export const LIST = [
  COLOR.PRIMARY,
  '#36A286',
  '#E3879E',
  '#1D3461',
  '#F4743B',
  '#483C46',
  '#A4036F',
  '#730071',
  '#FFEC51',
  '#A61C3C',
  '#F8958D',
  '#767676',
  '#FF8542',
  '#91685A',
  '#A932C8',
  '#B780C8',
  '#5C9DFF',
  '#7AC897',
  '#F53275',
  '#BEB2A6',
];

// NOTE: LIST の色と区別をしたい時に使用します。 LIST より少し色が薄いです。
const STACK_LIST = [
  '#959EF7',
  '#86C7B6',
  '#EEB7C5',
  '#7785A0',
  '#F8AC89',
  '#918A90',
  '#C868A9',
  '#AB66AA',
  '#FFF497',
  '#CA778A',
  '#FBBFBB',
  '#ADADAD',
  '#FFB68E',
  '#BDA49C',
  '#CB84DE',
  '#D4B3DE',
  '#9DC4FF',
  '#AFDEC1',
  '#F984AC',
  '#D8D1CA',
];

export const getColor = (index: number): string => {
  const colorNumber = index % LIST.length;
  return LIST[colorNumber];
};

export const getStackColor = (index: number): string => {
  const colorNumber = index % STACK_LIST.length;
  return STACK_LIST[colorNumber];
};
