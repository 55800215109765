import { MonthRangePickerPresenter } from './presenter';
import { RangePickerProps } from './types';

type Props = RangePickerProps;

import { startOfMonth, lastDayOfMonth } from 'date-fns';

export const MonthRangePicker: React.FC<Props> = (props) => {
  return (
    <MonthRangePickerPresenter
      {...props}
      startDate={props.startDate && startOfMonth(props.startDate)}
      endDate={props.endDate && lastDayOfMonth(props.endDate)}
      allowedDateRange={{
        startDate:
          props.allowedDateRange?.startDate &&
          startOfMonth(props.allowedDateRange?.startDate),
        endDate:
          props.allowedDateRange?.endDate &&
          lastDayOfMonth(props.allowedDateRange?.endDate),
      }}
    />
  );
};
