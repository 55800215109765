import styles from './styles.module.scss';

import clsx from 'clsx';
import React from 'react';

export type DateUnit = 'month' | 'halfYear' | 'year';
type Props<T extends DateUnit> = {
  selectedUnit: T;
  onClickUnit: (unit: T) => void;
  options: T[];
};

const LABELS: Record<DateUnit, string> = {
  month: '月',
  halfYear: '半期',
  year: '年',
};

export const SelectDateUnit: <T extends DateUnit>(
  props: Props<T>,
) => JSX.Element = (props) => {
  return (
    <div className={styles.base}>
      {props.options.map((unit, index) => (
        <React.Fragment key={unit}>
          {index !== 0 && <span>/</span>}
          <button
            className={clsx(
              styles.button,
              props.selectedUnit === unit && styles.active,
            )}
            onClick={() => props.onClickUnit(unit)}
          >
            {LABELS[unit]}
          </button>
        </React.Fragment>
      ))}
    </div>
  );
};
